import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css'
import "bootstrap"
import '@/assets/style.scss';
import mitt from 'mitt';
import router from "./router";

const emitter = mitt();

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.provide('emitter', emitter)

app.mount('#app')
