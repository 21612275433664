import { createWebHistory, createRouter } from "vue-router";
import {isLoggedIn} from "@/helpers/user";
import store from "@/state/store";

const router = createRouter({
	history: createWebHistory(),
	mode: 'history',
	routes: [

		{
			path: "/",
			name: "index",
			component: () => import("./views/HomePage.vue"),
			meta:{title:'Привет!'}
		},

		{
			path: "/positions-list",
			name: "positions-list",
			component: () => import("./views/lists/PositionsList"),
			meta:{title:'Позиции'}
		},

		{
			path: "/strategies-list",
			name: "strategies-list",
			component: () => import("./views/lists/StrategiesList"),
			meta:{title:'Список стратегий'}
		},
		{
			path: "/stats",
			name: "stats",
			component: () => import("./views/StatsPage"),
			meta:{title:'Статистика'}
		},
		{
			path: "/strategies-list",
			name: "strategies-list",
			component: () => import("./views/lists/StrategiesList"),
			meta:{title:'Стратегии'}
		},
		{
			path: "/strategy-create",
			name: "strategy-create",
			component: () => import("./views/StrategyEdit"),
			meta:{title:'Создание стратегии'}
		},
		{
			path: "/strategy-edit/:id",
			name: "strategy-edit",
			props: true,
			component: () => import("./views/StrategyEdit"),
			meta:{title:'Редактирование стратегии'}
		},
		{
			path: "/strategy-stats/:id",
			name: "strategy-stats",
			props: true,
			component: () => import("./views/StrategyStats"),
			meta:{title:'Статистика стратегии'}
		},


		{
			path: "/keys-create",
			name: "keys-create",
			component: () => import("./views/KeysEdit"),
			meta:{title:'Создание Ключей'}
		},
		{
			path: "/keys",
			name: "keys-list",
			component: () => import("./views/lists/KeysList"),
			meta:{title:'Список Ключей'}
		},
		{
			path: "/keys-edit/:id",
			name: "keys-edit",
			props: true,
			component: () => import("./views/KeysEdit"),
			meta:{title:'Редактирование Ключей'}
		},

		{
			path: "/pool-create",
			name: "pool-create",
			component: () => import("./views/PoolEdit"),
			meta:{title:'Создание Пула'}
		},
		{
			path: "/pools",
			name: "pools-list",
			component: () => import("./views/lists/PoolsList"),
			meta:{title:'Список Пулов'}
		},
		{
			path: "/pool-edit/:id",
			name: "pool-edit",
			props: true,
			component: () => import("./views/PoolEdit"),
			meta:{title:'Редактирование Пула'}
		},

		{
			path: "/symbols-status",
			name: "symbols-status",
			props: true,
			component: () => import("./views/SymbolsStatus"),
			meta:{title:'Отслеживаемые Пары'}
		},
		{
			path: "/platform-maintenance",
			name: "platform-maintenance",
			component: () => import("./views/PlatformMaintenance"),
			meta:{title:'Обслуживание Платформы'}
		},
		{
			path: "/trading-desk",
			name: "trading-desk",
			component: () => import("./views/TradeDesk"),
			meta:{title:'Торговля'}
		},
		{
			path: "/orders-list",
			name: "orders-list",
			component: () => import("./views/lists/OrdersList.vue"),
			meta:{title:'Ордера по символам'}
		},


		{
			path: "/login",
			name: "login",
			component: () => import("./views/forms/LoginForm.vue"),
			meta:{title:'Авторизация', allowGuest: true}
		},

		{
			path: "/signup",
			name: "signup",
			component: () => import("./views/forms/SignupForm"),
			meta:{title:'Регистрация', allowGuest: true}
		},
	]
})

router.beforeEach(async (routeTo, routeFrom) => {
	const allowAll = routeTo.matched.some((route) => route?.meta?.allowAll)
	const allowGuest = routeTo.matched.some((route) => route?.meta?.allowGuest)

	if(!allowGuest && !isLoggedIn()) {
		return '/login'
	}

	if(allowGuest && isLoggedIn()) {
		await store.dispatch('setErrorMessage', "Вы уже авторизованы")
		return "/"
	}
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  document.title = routeTo.meta.title + "    v." + process.env.VUE_APP_VERSION
  next()
})

export default router