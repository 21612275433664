import store from "@/state/store";
import back from "@/helpers/back"
import router from "@/router"

export const isLoggedIn = () => !!store.state?.vars?.access

export const login = (username, password) => {
	localStorage.clear()
	back('token/obtain/', {username: username, password: password}, 'post')
		.then(r => {
			if(r?.access) {
				return store.dispatch("setVar", {access: r.access, refresh: r.refresh})
					.then(() => {
						return router.push("/")
					})
			}
		})
}

export const logout = () => {
	return store.dispatch("setVar", {access: null, refresh: null})
		.then(() => {
			localStorage.clear()
			return router.push("/login")
		})
}

export const me = () => {

}

export const refreshAccess = () => {

}