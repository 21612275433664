import { createStore } from 'vuex'
import VuexPersist from 'vuex-persist';
// https://github.com/softvar/secure-ls
import SecureLS from "secure-ls";
let ls = new SecureLS({ isCompression: false });


const vuexLocalStorage = new VuexPersist({
  key: 'stftstorage',
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
  // storage: window.localStorage, // or window.sessionStorage or localForage
})

const state = {
  messages: [],
  error_message: null,
  loading: {},
  vars : {},
  access:null,
  refresh:null,
}

const getters = {
  getMessages: (state) => state.messages,
  getErrorMessage : (state) => state.error_message,
  getLoading : (state) => state.loading,
  getVars: (state) => state.vars
}

const actions = {
  setMessages : ({ commit }, payload) => commit('settingMessages', payload),
  setErrorMessage : ({ commit }, payload) => commit('settingErrorMessage', payload),
  setLoading: ({commit}, payload) => commit('settingLoading', payload),
  stopLoading: ({commit}, payload) => commit('stoppingLoading', payload),
  setVar: ({commit}, payload) => commit('settingVar', payload)
}

const mutations = {
  settingMessages: (state, message) => state.messages.append(message),
  settingErrorMessage: (state, message) => state.error_message = message,
  settingLoading: (state, message) => {
    // state.loading[message] = true
    state.loading = Object.assign( {}, state.loading, {[message]:true})
  },
  stoppingLoading: (state, message) => {
    if(message in state.loading) delete state.loading[message]
  },
  settingVar: (state, payload) => state.vars = Object.assign({}, state.vars, payload)
}

const store = createStore({
  state,
  getters,
  actions,
  mutations,
  plugins: [vuexLocalStorage.plugin]
})

export default store